/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'normalize.css/normalize.css'

import './src/styles/rhetoric-font.css'
import './src/styles/global.css'
