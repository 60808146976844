module.exports = [{
      plugin: require('/opt/build/repo/_app/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/_app/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/opt/build/repo/_app/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tamburetei","short_name":"Tamburetei","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"content/assets/icon.svg"},
    },{
      plugin: require('/opt/build/repo/_app/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
