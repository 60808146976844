// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-subject-js": () => import("/opt/build/repo/_app/src/templates/Subject.js" /* webpackChunkName: "component---src-templates-subject-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/_app/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cadeiras-js": () => import("/opt/build/repo/_app/src/pages/cadeiras.js" /* webpackChunkName: "component---src-pages-cadeiras-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/_app/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

